import React from 'react';
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root')!;
const root = createRoot(container);

// css
import './index.scss'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import { Main } from './Main'

root.render(
  <React.StrictMode>
    <div className='bg-slate-200 min-h-screen'>
      <Main />
    </div>
  </React.StrictMode>
);
