import React, {
  useState
}
from 'react'

import Slider from 'react-slick'
import Markdown from 'react-markdown'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  faSquarePhone,
  faHome,
  faMapLocationDot,
  faRectangleXmark,
} from '@fortawesome/free-solid-svg-icons'
import {
  faLine,
  faSquareFacebook,
  faSquareXTwitter,
  faSquareInstagram,
} from '@fortawesome/free-brands-svg-icons'

import {
  SpotType,
} from '../../types'

interface ModalProps {
  toggleBtn?: JSX.Element,
  spot: SpotType,
}

export const Modal = ({
  toggleBtn = (<div>Toggle</div>),
  spot,
}: ModalProps) => {
  const [visible, set_visible] = useState(false)

  const ImageSlider = () => {
    const images = spot.images?.map(image => image.image)
    if (images && images.length) {
      const slider_setting = {
        dots: false,
        infinite: true,
        arrows: false,
        speed: 1200,
        adaptiveHeight: true,
        pauseOnHover: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000
      }

      const ImageElements = images.map( (image, key) => {
        return (
          <img
            key={ key }
            src={ `${process.env.REACT_APP_BACKEND_HOST}${image}` }
            className='block align-middle' />
        )
      })

      return (
        <div className='md:mr-5 mb-5 md:float-left w-full md:w-1/2 border-gray-700 border-solid border'>
          <Slider { ...slider_setting }>{ ImageElements }</Slider>
        </div>
      )
    }
  }

  let shadowColor = ''
  switch (spot.tags?.[0].color) {
    case 'sky':
      shadowColor = 'shadow-sky-100'
      break
    case 'emerald':
      shadowColor = 'shadow-emerald-100'
      break
    case 'orange':
      shadowColor = 'shadow-orange-100'
      break
    case 'indigo':
      shadowColor = 'shadow-indigo-100'
      break
    case 'purple':
      shadowColor = 'shadow-purple-100'
      break
    default:
      shadowColor = 'shadow-gray-100'
  }

  const Accesses = () => {
    const contents = [
      ['home_page', faHome],
      ['google_map', faMapLocationDot],
      ['phone', faSquarePhone],
      ['line', faLine],
      ['instagram', faSquareInstagram],
      ['twitter', faSquareXTwitter],
      ['facebook', faSquareFacebook],
      ['close', faRectangleXmark],
    ]
    return contents.map((content, key) => {
      let k = content[0] as 'home_page' | 'google_map' | 'phone' | 'line' | 'instagram' | 'twitter' | 'facebook' | 'close'

      switch (k) {
        case 'close':
          return (
            <button
              key={key}
              onClick={ () => { set_visible(false) } }
              className='block cursor-pointer text-rose-800 hover:text-slate-800'
            >
              <FontAwesomeIcon
                icon={content[1] as IconProp}
                className='block w-10 h-10' />
            </button>
          )
        default:
          if (spot[k] && spot[k] != '') {
            return(
              <a
                key={key}
                href={ k === 'phone' ? `tel:${spot[k]}` : spot[k]}
                target='_blank'
                className='block cursor-pointer text-sky-800 hover:text-slate-800'
              >
                <FontAwesomeIcon
                  icon={content[1] as IconProp}
                  className='block w-10 h-10' />
              </a>
            )
          }
      }
    })
  }

  return (
    <>
      <div
        className={ `cursor-pointer ${visible ? ' hidden' : ''}` }
        onClick={ () => { set_visible(true) } }>
        { toggleBtn }
      </div>
      <div
        className={ `fixed z-20 top-0 left-0 w-screen h-screen ${ visible ? '' : 'hidden' }` }>
        <div
          onClick={ () => { set_visible(false) } }
          className='absolute top-0 left-0 w-full h-full bg-black/75'></div>
        <div className={`absolute top-1/2 left-1/2 w-[92%] lg:w-10/12 h-[96%] lg:h-[90%] overflow-y-auto rounded-lg bg-white p-5 -translate-x-1/2 -translate-y-1/2 shadow-[0_0_6px_4px] ${shadowColor}`}>

          <ImageSlider />

          <div className='md:h-full flex flex-col items-stretch'>
            <div className='flex-1'>
              <h2 className='text-center mb-3'>{ spot.name }</h2>
              <Markdown>{ spot.description }</Markdown>
            </div>
            <div className='flex-none flex flex-row flex-wrap items-center justify-end gap-x-3 gap-y-3 mt-10'>
              <Accesses />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
